.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.ant-notification-notice-description {
  white-space: pre-line;
}
.pagebreak {
  page-break-before: always;
}

h1 {
  font-size: 20px;
}

iframe {
    display: none !important;
}